import * as React from "react"
import Layout from '../components/layout'
import '../styles/home.scss'
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

// markup
const IndexPage = () => {
  
  return (
    <Layout>
      <main>
        <title>Welcome to DD Zone</title>
        {/* section top start */}
          <section className='section-heading'>
            <div>
                <p className='main-title'>Built for restaurants. Built for you.</p>
                <p className='main-sub-title'>Manage your: invoices, customers, online sales, reports, stores, and much more, all in one place.</p>
            </div>
            <div className='talk-section'>
                <div>
                    <p>Ready to see how we can transform your business?</p>
                </div>
                <div>
                    <button className='button-talk'>
                        <Link to='/pricing'>Get Started</Link>
                    </button>
                    <button className='button-talk'>
                        <a href='https://calendly.com/quantic/15min' target='_blank'>Let's Talk</a>
                    </button>
                </div>
            </div>
            <div className='product-image'>
                <StaticImage src='../images/all-product-image-2048.png' alt='productImage'/>
            </div>
          </section>
        {/* section top end */}

        {/* section features start */}
          <section>
            <div className='feature-container'>
              <div className='feature-detail'>
                  <p className='title'>Online Ordering</p>
                  <p className='sub-title'>Take your business online with a free eCommerce website having your own branding.
                      Increase productivity with an eCommerce solution that synchronizes orders, inventory, and customer data across all channels.
                      The DD Zone eCommerce is fully optimized for mobile, tablets and desktops providing a pleasant user experience that will drive sales.
                      Allow your customers to avoid peak hour delays by ordering in advance.
                  </p>
              </div>
              <div>
                  {/*<img className='feature-image oos'/>*/}
                  <StaticImage alt="oos" src="../images/online-ordering.svg"/>
              </div>
            </div>
              <hr/>
            <div className='feature-container'>
              <div>
                  <StaticImage alt="payments" src="../images/payments.svg"/>
              </div>
              <div className='feature-detail'>
                  <p className='title'>Payments</p>
                  <p className='sub-title'>Offer your customers secure and convenient online payment solutions today.
                      Accept all the ways your customers want to pay with a seamless and secure checkout experience,
                      Cash, Card (swipe/tap/dip card or enter card info manually), Wallet (Apple Pay, Samsung Pay, Google Pay).
                      With the Cash Discount program, you can provide customers with an incentive to pay with cash.</p>
              </div>
            </div>
              <hr/>
            <div className='feature-container'>
               <div className='feature-detail'>
                {/*<div>*/}
                  <p className='title'>Register Management</p>
                  <p className='sub-title'>Be more organized with your cash flow and shift rotations with the open/close register feature.
                    View cash flow details from each shift.</p>
                {/*</div>*/}
               </div>
                <div>
                    <StaticImage alt="register" src="../images/register.svg"/>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div>
                    <StaticImage alt="catalog" src="../images/catalog.svg"/>
                </div>
                <div className='feature-detail'>
                  <p className='title'>Catalog Management</p>
                  <p className='sub-title'>Add an unlimited amount of products and variants,
                      From creating new products and variations to images, display names and more.
                    Increase business by adding more choices for customers with modifiers,
                    Save time and bulk import inventory with few clicks.</p>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div className='feature-detail'>
                    <p className='title'>Tip Management</p>
                    <p className='sub-title'>Customize your tip settings to best suit your business and easily collect tips from customers,
                      so your employees will be more driven and have higher overall morale. Managers are able to view and do shift close outs.</p>
                </div>
                <div>
                    <StaticImage alt="tip" src="../images/tip.svg"/>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div>
                    <StaticImage alt="order" src="../images/order-management.svg"/>
                </div>
                <div className='feature-detail'>
                    <p className='title'>Order Management</p>
                    <p className='sub-title'>Provide refunds for individual items, entire order or manual refunds with ability to edit amount refunded.
                        View all current and previous orders on one screen. Email or print the receipt at any time, view order history and details.
                    </p>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div className='feature-detail'>
                    <p className='title'>Inventory Management</p>
                    <p className='sub-title'>Easily record and adjust stock of damaged, lost, or stolen items in specified stores.
                        Increase profits by preventing stock shortages with Purchase Order Receiving.</p>
                </div>
                <div>
                    <StaticImage alt="inventory" src="../images/inventory.svg"/>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div>
                    <StaticImage alt="invoicing" src="../images/invoice.svg"/>
                </div>
                <div className='feature-detail'>
                    <p className='title'>Invoicing</p>
                    <p className='sub-title'>Send your customer an invoice and get paid faster by accepting payments 24/7.
                        Send digital invoices, accept payments, send due reminders,
                      and track all the invoices in one place from anywhere with our cloud based solution.</p>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div className='feature-detail'>
                    <p className='title'>Kitchen Display</p>
                    <p className='sub-title'>Keep perfect track of orders during rush hours,
                        Eliminate bottlenecks in your kitchen and communicate efficiently between your kitchen staff and waiters.
                        Use your tablet to flick through your customer orders, mark items off on the go and keep the kitchen running smoothly.
                        Your Kitchen Display System powers up your restaurant by tracking prep times,
                        routing orders to their correct stations, and enabling custom ticket timers for maximum efficiency.</p>
                </div>
                <div>
                    <StaticImage alt="kds" src="../images/kds.svg"/>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div>
                    <StaticImage alt="customers" src="../images/customers.svg"/>
                </div>
                <div className='feature-detail'>
                    <p className='title'>Customer Management</p>
                    <p className='sub-title'>Enable more efficient future service for customers by keeping records of their information.
                      Customer profiles are auto created with every in-store or online purchase during checkout.
                      Send marketing materials to customers by tracking their email addresses.</p>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div className='feature-detail'>
                    <p className='title'>Pay-By-Text</p>
                    <p className='sub-title'>Send your customer a text where they can easily pay online contactless from anywhere.
                        Regardless if your customer is on the phone or in your store you can send them a text message with a payment link for them to go online on their desktop, laptop, tablet, and even phone and pay you quickly, easily, and securely.
                        This is also a great contactless solution for you and your customers.
                        Remarkably flexible and shareable.</p>
                </div>
                <div>
                    <StaticImage alt="transactions" src="../images/invoicing.svg"/>
                </div>
            </div>
              <hr/>
            <div className='feature-container'>
                <div>
                    <StaticImage alt="reports" src="../images/reports.svg"/>
                </div>
                <div className='feature-detail'>
                    <p className='title'>Reporting</p>
                    <p className='sub-title'>Generate reports to review your finances including sales, returns, taxes, payments, and more.
                        Get insights into which products are and are not selling.
                        Access actionable insight instantly, whether you want to track your business turnover or profit margins.
                        Access key metrics, receive real-time updates on stock, sales and staff performance.</p>
                </div>
            </div>

          </section>
        {/* section features end */}
      </main>
    </Layout>
  )
}

export default IndexPage
